import React from 'react'
import { Layout } from '../../components';

const ContactUs = () => {
  return (
    <Layout>
    <div className="container-fuild" style={{ backgroundColor: '#f4f9f7' }}>
      <h1>ContactUs</h1>
    </div>
    </Layout>
  )
}
;
export default ContactUs;