import React from 'react'
import { Layout } from '../../components';

const FAQScreen = () => {
  return (
      <Layout>
      <div className="container-fuild" style={{ backgroundColor: '#f4f9f7' }}>
        <h1>FAQScreen</h1>
      </div>
      </Layout>
  )
}
;
export default FAQScreen;