const ROUTE_PATHS = {
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  DASHBOARD: '/',
  PRODUCT: '/product/:category/:id', // Add category route
  PRODUCT_DETAILS: '/product-details/:id',
  EditProfile: '/edit-profile',

  // Support
  TERMSANDCONDITIONS: '/terms-and-conditions',
  PRIVACYPOLICY: '/privacy-policy',
  FAQS: '/faqs',
  CONTACTUS: '/contact-us',
  ABOUTUS: '/about-us',
  THANKYOUSCREEN: '/thank-you'
};

export default ROUTE_PATHS;
