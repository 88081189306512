import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, A11y } from 'swiper/modules';
import { FaArrowRightLong, FaArrowLeftLong } from "react-icons/fa6";
import { Category } from "../../../interfaces/CategoryProps";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../../routes";

const CategoryList: React.FC = () => {
    const navigate = useNavigate();
    const [categories, setCategories] = useState<Category[]>([]);

    // Fetch categories from API or dummy data
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch('https://moodle.mishidemo.online/api/getAllCategories');
                if (!response.ok) {
                    throw new Error('Failed to fetch categories');
                }
                const data: Category[] = await response.json();
                setCategories(data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategories();
    }, []);

    // Swiper settings
    const swiperSettings = {
        modules: [Navigation, Pagination, A11y],
        spaceBetween: 20,
        pagination: false,
        breakpoints: {
            640: { slidesPerView: 1 },
            768: { slidesPerView: 2 },
            1024: { slidesPerView: 3 },
        },
    };

    // Ref to access Swiper instance
    const swiperRef = React.useRef<any>(null);

    // Handle Previous and Next buttons
    const handlePrev = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const handleNext = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slideNext();
        }
    };

    return (
        <div className="container">
            {categories.length > 0 ? <div className='container py-3 p-0'><h2 className='fw-bold'>Popular Category</h2></div> : <></> }
            {categories.length <= 2 ? (
                // Case 1: If categories length <= 2
                <div className="row">
                    {categories.map((category) => (
                        <div className="col-md-6" key={category.id} onClick={() => navigate(ROUTE_PATHS.PRODUCT
    .replace(':category', encodeURIComponent(category.name))
    .replace(':id', category.id.toString())
                        )
                        } style={{cursor: "pointer"}}>
                            <div className="card mb-3" style={{ borderRadius: "5px", overflow: "hidden" }}>
                                <div className="card-body p-0" style={{ position: "relative" }}>
                                    {category.img ? (
                                        <img
                                            src={`http://moodle.mishidemo.online/uploads/category/${category.img}`}
                                            alt={category.name}
                                            className="img-fluid"
                                            style={{
                                                width: "100%",
                                                height: "320px",
                                                borderRadius: "5px",
                                                objectFit: "cover", // Ensures the image covers the area without stretching
                                            }}
                                        />
                                    ) : (
                                        <p>No Image Available</p>
                                    )}

                                    {/* Overlay on Image */}
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            backgroundColor: "rgba(0, 0, 0, 0.55)", // Black transparent overlay
                                            display: "flex",
                                            justifyContent: "flex-start", // Aligning content to the left
                                            alignItems: "flex-end", // Aligning content to the bottom
                                            padding: "10px",
                                            borderRadius: "5px",
                                        }}
                                    >
                                        <h3 style={{ color: "#fff", margin: 15 }}>{category.name}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : categories.length === 3 ? (
                // Case 2: If categories length is 3
                <div className="row">
                    {categories.map((category) => (
                        <div className="col-md-4" key={category.id} onClick={() => navigate(ROUTE_PATHS.PRODUCT
                            .replace(':category', encodeURIComponent(category.name))
                            .replace(':id', category.id.toString()))} style={{cursor: "pointer"}}>
                            <div className="card mb-3" style={{ borderRadius: "5px", overflow: "hidden" }}>
                                <div className="card-body p-0" style={{ position: "relative" }}>
                                    {category.img ? (
                                        <img
                                            src={`http://moodle.mishidemo.online/uploads/category/${category.img}`}
                                            alt={category.name}
                                            className="img-fluid"
                                            style={{
                                                width: "100%",
                                                height: "260px",
                                                borderRadius: "5px",
                                                objectFit: "cover", // Ensures the image covers the area without stretching
                                            }}
                                        />
                                    ) : (
                                        <p>No Image Available</p>
                                    )}

                                    {/* Overlay on Image */}
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            backgroundColor: "rgba(0, 0, 0, 0.55)", // Black transparent overlay
                                            display: "flex",
                                            justifyContent: "flex-start", // Aligning content to the left
                                            alignItems: "flex-end", // Aligning content to the bottom
                                            padding: "10px",
                                            borderRadius: "5px",
                                        }}
                                    >
                                        <h4 style={{ color: "#fff", margin: 15 }}>{category.name}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                // Case 3: If categories length > 3
                <>
                    <div className="swiper-container" style={{ position: "relative" }}>
                        <Swiper {...swiperSettings} ref={swiperRef}>
                            {categories.map((category) => (
                                <SwiperSlide key={category.id} onClick={() => navigate(ROUTE_PATHS.PRODUCT
                                    .replace(':category', encodeURIComponent(category.name))
                                    .replace(':id', category.id.toString()))} style={{cursor: "pointer"}}>
                                    <div className="card mb-3" style={{ borderRadius: "5px", overflow: "hidden" }}>
                                        <div className="card-body p-0" style={{ position: "relative" }}>
                                            {category.img ? (
                                                <img
                                                    src={`http://moodle.mishidemo.online/uploads/category/${category.img}`}
                                                    alt={category.name}
                                                    className="img-fluid"
                                                    style={{
                                                        width: "100%",
                                                        height: "280px",
                                                        borderRadius: "5px",
                                                        objectFit: "cover", // Ensures the image covers the area without stretching
                                                    }}
                                                />
                                            ) : (
                                                <p>No Image Available</p>
                                            )}

                                            {/* Overlay on Image */}
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    backgroundColor: "rgba(0, 0, 0, 0.55)", // Black transparent overlay
                                                    display: "flex",
                                                    justifyContent: "flex-start", // Aligning content to the left
                                                    alignItems: "flex-end", // Aligning content to the bottom
                                                    padding: "10px",
                                                    borderRadius: "5px",
                                                }}
                                            >
                                                <h4 style={{ color: "#fff", margin: 15 }}>{category.name}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>

                        {/* Custom Left and Right Arrows Row */}
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                width: "100%",
                                marginTop: "10px",
                            }}
                        >
                            <FaArrowLeftLong
                                onClick={handlePrev}
                                size={20}
                                style={{ marginRight: "10px", cursor: "pointer" }}
                            />
                            <FaArrowRightLong
                                onClick={handleNext}
                                size={20}
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default CategoryList;
