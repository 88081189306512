import React from 'react';
import { FeatureList, Layout, Slider } from '../../components';
import { useNavigate } from 'react-router-dom';  
import CategoryList from './components/CategoryList';

const Dashboard: React.FC = () => {
    const navigate = useNavigate(); // For programmatic navigation

    const sliderContent = [
        "https://demo-alukas.myshopify.com/cdn/shop/files/alukas-slide-11.jpg",
        "https://demo-alukas.myshopify.com/cdn/shop/files/alukas-slide-13.jpg",
        "https://demo-alukas.myshopify.com/cdn/shop/files/alukas-slide-12.jpg",
        "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
    ]; 

    return (
        <Layout>
            <Slider sliderContent={sliderContent} />
            <FeatureList />
            <CategoryList />
            <br/>
        </Layout>
    );
};

export default Dashboard;
