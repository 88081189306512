import React from 'react';
import { To, useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../routes';

const Footer: React.FC = () => {

  const navigate = useNavigate();

  const handleNavigation = (path: To) => {
    navigate(path);
  };

    return (
      <footer className="py-4 footer">
        <div className="container-fluid py-4 px-4">
          <div className="row align-items-start">
            {/* Left Side - About Section */}
            <div className="col-md-6 footer-left">
              <h2>The Print Pyramid</h2><br />
              <p>theprintpyramids@gmail.com<br /></p>
              <p style={{fontSize: 12, color: "white", opacity: 0.8}}>Resevill Sky, A-320, Nikol-Naroda Rd,<br />opp. Pushkar Icon, New Naroda, Nikol,<br />Ahmedabad, Gujarat 382350<br />Phone: +91 940 944 4040</p>
            </div>
  
            {/* Right Side - Additional Sections */}
            <div className="col-md-6 footer-right">
              <div className="row d-flex justify-content-between">
                <div className="col-md-4 mb-4 mb-md-0">
                  <h5>Need Help</h5>
                  <ul className="list-unstyled text-secondary" style={{fontSize: 13 }}>
                    <li onClick={() => {}} style={{ cursor: "pointer" }}>Track Order</li>
                    <li onClick={() => handleNavigation(ROUTE_PATHS.FAQS)} style={{ cursor: "pointer" }}>FAQs</li>
                    <li onClick={() => {}} style={{ cursor: "pointer" }}>Returns & Refunds</li>
                    <li onClick={() => handleNavigation(ROUTE_PATHS.CONTACTUS)} style={{ cursor: "pointer" }}>Contact Us</li>
                  </ul>
                </div>
                <div className="col-md-4 mb-4 mb-md-0">
                  <h5>Company</h5>
                  <ul className="list-unstyled text-secondary" style={{fontSize: 13 }}>
                  <li onClick={() => handleNavigation(ROUTE_PATHS.ABOUTUS)} style={{ cursor: "pointer" }}>About Us</li>
                  <li onClick={() => {}} style={{ cursor: "pointer" }}>Achatsian</li>
                    <li onClick={() => {}} style={{ cursor: "pointer" }}>Collaboration</li>
                    <li onClick={() => {}} style={{ cursor: "pointer" }}>TPP Blog</li>
                    <li onClick={() => {}} style={{ cursor: "pointer" }}>Media</li>
                  </ul>
                </div>
                <div className="col-md-4">
                  <h5>More info</h5>
                  <ul className="list-unstyled text-secondary" style={{fontSize: 13 }}>
                    <li onClick={() => handleNavigation(ROUTE_PATHS.TERMSANDCONDITIONS)} style={{ cursor: "pointer" }}>Terms & Condition</li>
                    <li onClick={() => handleNavigation(ROUTE_PATHS.PRIVACYPOLICY)} style={{ cursor: "pointer" }}>PrivacyPolicy</li>
                    <li onClick={() => {}} style={{ cursor: "pointer" }}>Shipping Policy</li>
                    <li onClick={() => {}} style={{ cursor: "pointer" }}>Sitemap</li> 
                  </ul>
                </div>
              </div>
            </div>
          </div>
  
          {/* Divider Section */}
          <hr className="my-4 border-light" /> {/* Bootstrap horizontal rule with light border */}
  
          {/* Copyright Section */}
          <div className="text-center mt-4">
            <small>&copy; 2024 Your Company. All rights reserved.</small>
          </div>
        </div>
      </footer>
    );
  };
  
export default Footer;
