import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom'; 
import { ROUTE_PATHS } from '.'; // Ensure your ROUTE_PATHS contains the correct paths
import { ForgotPassword, Login, Register } from '../pages/auth';
import { Dashboard, ProductDetails } from '../pages/dashboard';
import { AboutUs, ContactUs, FAQScreen, PrivacyPolicy, TermsAndConditions, ThankYouScreen } from '../pages/support';
import EditProfile from '../pages/Profile/EditProfile';
import Product from '../pages/dashboard/product/Products';

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={ROUTE_PATHS.LOGIN} element={<Login />} />
      <Route path={ROUTE_PATHS.REGISTER} element={<Register />} />
      <Route path={ROUTE_PATHS.FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route path={ROUTE_PATHS.DASHBOARD} element={<Dashboard />} />
      <Route path={ROUTE_PATHS.PRODUCT} element={<Product />} /> 
      <Route path={ROUTE_PATHS.EditProfile} element={<EditProfile />}/>
      <Route path={ROUTE_PATHS.PRODUCT_DETAILS} element={<ProductDetails />} /> 

      {/* Support */}
      <Route path={ROUTE_PATHS.TERMSANDCONDITIONS} element={<TermsAndConditions />} />
      <Route path={ROUTE_PATHS.PRIVACYPOLICY} element={<PrivacyPolicy />} />
      <Route path={ROUTE_PATHS.ABOUTUS} element={<AboutUs />} />
      <Route path={ROUTE_PATHS.CONTACTUS} element={<ContactUs />} />
      <Route path={ROUTE_PATHS.FAQS} element={<FAQScreen />} />
      <Route path={ROUTE_PATHS.THANKYOUSCREEN} element={<ThankYouScreen />} />

      <Route path="*" element={<Navigate to={ROUTE_PATHS.LOGIN} />} />
    </Routes>
  );
};

export default AppRoutes;
