import React, { useState } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { FaShoppingCart, FaBars } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Drawer, Space } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { logo } from '../assets';

const Header: React.FC = () => {
    const [open, setOpen] = useState<boolean>(false);

    const showDrawer = (): void => {
        setOpen(true);
    };

    const onClose = (): void => {
        setOpen(false);
    };

    return (
        <>
            {/* Drawer for mobile menu */}
            <Drawer
                title="The Print Pyramid"
                onClose={onClose}
                open={open}
                closable={false}
                placement="bottom"
                extra={
                    <Space>
                        <CloseOutlined
                            onClick={onClose}
                            className="drawer-close-icon"
                        />
                    </Space>
                }
            >
                <Nav className="flex-column">
                    <Nav.Link as={Link} to="/login" className="drawer-link">
                        Login / Register
                    </Nav.Link>
                </Nav>
            </Drawer>

            {/* Main Navbar */}
            <Navbar expand="lg" className="appbar sticky-top">
                <Container fluid className='px-4'>
                    <Navbar.Brand as={Link} to="/" className="d-flex align-items-center justify-content-center">
                        <img
                            src={logo}
                            alt="The Print Pyramid Logo"
                            className="navbar-logo"
                        />
                        <span className="app-title ms-2">Print Pyramid</span> {/* Added margin to create space */}
                    </Navbar.Brand>

                    {/* Custom button for mobile drawer */}
                    <FaBars
                        className="menu-icon d-lg-none"
                        onClick={showDrawer}
                    />

                    <Navbar.Collapse
                        id="basic-navbar-nav"
                        className="d-none d-lg-block"
                    >
                        <Nav className="ms-auto">
                            <Nav.Link as={Link} to="/login" className="nav-link">
                                Login / Register
                            </Nav.Link>
                            <Nav.Link href="#cart">
                                <FaShoppingCart className="cart-icon" />
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};

export default Header;
