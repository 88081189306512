// components/Slider.tsx
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, EffectFade } from 'swiper/modules';
import { useMediaQuery } from 'react-responsive';

interface SliderProps {
  sliderContent: string[];
}

const Slider: React.FC<SliderProps> = ({ sliderContent }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  return (
    <Swiper
      effect={'fade'}
      pagination={{
        dynamicBullets: true,
        clickable: true,
      }}
      modules={[Autoplay, Pagination, EffectFade]}
      spaceBetween={50}
      slidesPerView={'auto'}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      loop
    >
      {sliderContent.map((item, index) => (
        <SwiperSlide key={index}>
          <div className="slider-item">
            {item.endsWith('.mp4') ? (
              <video className="slider-media" autoPlay muted loop>
                <source src={item} type="video/mp4" />
              </video>
            ) : (
              <div
              className="slider-item"
              style={{
                position: 'relative', // To position the overlay relative to the image
              }}
            >
              {/* Image */}
              <img
                src={item}
                alt={`Slide ${index}`}
                className="slider-media"
                style={{
                  objectFit: 'cover', // Ensures the image maintains its aspect ratio and fills the container
                }}
              />
              {/* Black Overlay and Text */}
              <div
                style={{
                  position: 'absolute', // Overlay on top of the image
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.6)', // Black overlay with 0.5 opacity
                }}
              >
                <div
                className='container'
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start', // Align content to the left
                    alignItems: 'flex-start', // Align text to the top of the container
                    height: '100%', // Ensure the container fills the overlay
                    padding: '2rem', // Add padding to prevent text from touching the edges
                  }}
                >
                  <div
                    style={{
                      width: isTabletOrMobile ? '80%' : '50%', // Set the width to 6 columns (50% of the parent width)
                      paddingTop: isTabletOrMobile ? '35px' : '60px'
                    }}
                  >
                    {/* Title */}
                    <h2
                      style={{
                        color: '#fff',
                        fontSize: isTabletOrMobile ? '1.6rem' : '3rem', // Larger font size for the title
                        fontWeight: 'bold', // Bold title for emphasis
                        textTransform: 'uppercase', // Add uppercase styling for more impact
                        letterSpacing: '1px', // Adds letter spacing for better visual separation
                        marginBottom: '0.5rem', // Space between title and subtitle
                      }}
                    >
                      The Ultimate Experience
                    </h2>
                    {/* Subtitle */}
                    <p
                      style={{
                        color: '#ccc', // Lighter gray for the subtitle
                        fontSize: isTabletOrMobile ? '0.7rem' : '1.3rem', // Slightly larger than default for better readability
                        lineHeight: '1.6', // Better readability
                        fontStyle: 'italic', // Add italics for a more stylish look
                        fontWeight: '300', // Lighter weight for the subtitle
                      }}
                    >
                      Get ready for the most thrilling adventure with mind-blowing visuals and captivating storytelling.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            

            )}
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Slider;
