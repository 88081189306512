import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../../components';
import { ROUTE_PATHS } from '../../../routes';
import { FaHeart } from 'react-icons/fa';

const Product: React.FC = () => {
    const navigate = useNavigate();

    const products = {
        "id": 1,
        "title": "Men",
        "image": "url",
        "data": [
            {
                "id": 1,
                "title": "Rounded",
                "image": "https://oldnavy.gap.com/webcontent/0017/820/830/cn17820830.jpg",
                "desc": "A classic round-neck t-shirt suitable for casual wear.",
                "color": [
                    { "id": 1, "title": "Red" },
                    { "id": 2, "title": "Blue" }
                ],
                "size": [
                    { "id": 1, "title": "M" },
                    { "id": 2, "title": "L" }
                ],
                "material": "Cotton",
                "size&fit": "Regular Fit",
                "specification": "Soft and breathable fabric.",
                "review": [],
                "productDetailsBanner": "url",
                "printType": [
                    {
                        "id": 1,
                        "title": "Front",
                        "image": "url",
                        "sizes": [
                            { "id": 1, "title": "A4" }
                        ],
                        "uplaodURL": "url"
                    }
                ],
                "designStyle": [
                    "https://tse1.mm.bing.net/th?id=OIP.7G61u8fcs48sFhlkxe-LjQHaLH&pid=Api",
                    "https://tse2.mm.bing.net/th?id=OIP.DPK2IFABmj2jrK1FjuoWAwHaKP&pid=Api"
                ]
            },
            {
                "id": 2,
                "title": "V-Neck",
                "image": "https://cdnd.lystit.com/photos/ba96-2014/11/25/asos-black-knitted-t-shirt-with-turtleneck-product-1-25758378-1-949490312-normal.jpeg",
                "desc": "Stylish V-neck t-shirt for a modern look.",
                "color": [
                    { "id": 1, "title": "Black" },
                    { "id": 2, "title": "White" }
                ],
                "size": [
                    { "id": 1, "title": "S" },
                    { "id": 2, "title": "M" }
                ],
                "material": "Blended Fabric",
                "size&fit": "Slim Fit",
                "specification": "Durable and comfortable.",
                "review": [],
                "productDetailsBanner": "url",
                "printType": [],
                "designStyle": []
            },
            {
                "id": 3,
                "title": "Polo",
                "image": "https://cdn-img.prettylittlething.com/0/6/6/4/06648b7716075d32040b63ec66ba7d053106ed96_cmb5279_1.jpg",
                "desc": "Collared polo t-shirt for a semi-formal look.",
                "color": [
                    { "id": 1, "title": "Green" },
                    { "id": 2, "title": "Yellow" }
                ],
                "size": [
                    { "id": 1, "title": "L" },
                    { "id": 2, "title": "XL" }
                ],
                "material": "Polyester",
                "size&fit": "Comfort Fit",
                "specification": "Moisture-wicking material.",
                "review": [],
                "productDetailsBanner": "url",
                "printType": [],
                "designStyle": []
            },
            {
                "id": 4,
                "title": "Sleeveless",
                "image": "https://i1.adis.ws/i/boohooamplience/mzz78251_ecru_xl?%24product_page_main_magic_zoom%24=",
                "desc": "Sleeveless t-shirt, perfect for gym wear.",
                "color": [
                    { "id": 1, "title": "Grey" },
                    { "id": 2, "title": "Blue" }
                ],
                "size": [
                    { "id": 1, "title": "S" },
                    { "id": 2, "title": "M" }
                ],
                "material": "Polyester",
                "size&fit": "Slim Fit",
                "specification": "Stretchable and lightweight.",
                "review": [],
                "productDetailsBanner": "url",
                "printType": [],
                "designStyle": []
            },
            {
                "id": 5,
                "title": "Henley",
                "image": "https://i.pinimg.com/originals/27/14/30/271430373427841dfa1eae59934eab54.jpg",
                "desc": "Buttoned Henley t-shirt for a smart-casual style.",
                "color": [
                    { "id": 1, "title": "Brown" },
                    { "id": 2, "title": "Black" }
                ],
                "size": [
                    { "id": 1, "title": "M" },
                    { "id": 2, "title": "L" }
                ],
                "material": "Cotton",
                "size&fit": "Regular Fit",
                "specification": "Soft and breathable.",
                "review": [],
                "productDetailsBanner": "url",
                "printType": [],
                "designStyle": []
            },
            {
                "id": 6,
                "title": "Longline",
                "image": "https://gloimg.drlcdn.com/L/pdm-product-pic/Clothing/2016/06/23/source-img/20160623095022_69508.jpg",
                "desc": "Extended hem longline t-shirt for streetwear enthusiasts.",
                "color": [
                    { "id": 1, "title": "White" },
                    { "id": 2, "title": "Black" }
                ],
                "size": [
                    { "id": 1, "title": "M" },
                    { "id": 2, "title": "L" }
                ],
                "material": "Cotton",
                "size&fit": "Loose Fit",
                "specification": "Trendy and comfortable.",
                "review": [],
                "productDetailsBanner": "url",
                "printType": [],
                "designStyle": []
            },
            {
                "id": 7,
                "title": "Crop",
                "image": "https://www.topofstyle.com/blog/wp-content/uploads/2017/04/One-shoulder-longline.jpg",
                "desc": "Crop top t-shirt for a casual yet chic look.",
                "color": [
                    { "id": 1, "title": "Pink" },
                    { "id": 2, "title": "Yellow" }
                ],
                "size": [
                    { "id": 1, "title": "S" },
                    { "id": 2, "title": "M" }
                ],
                "material": "Blended Fabric",
                "size&fit": "Slim Fit",
                "specification": "Stylish and lightweight.",
                "review": [],
                "productDetailsBanner": "url",
                "printType": [],
                "designStyle": []
            },
            {
                "id": 8,
                "title": "Striped",
                "image": "https://cdnc.lystit.com/photos/d830-2015/01/11/asos-white-super-longline-t-shirt-with-side-zip-detail-and-oversized-boxy-fit-product-1-27021898-1-758948080-normal.jpeg",
                "desc": "Striped t-shirt for a vibrant look.",
                "color": [
                    { "id": 1, "title": "Blue-White" },
                    { "id": 2, "title": "Black-White" }
                ],
                "size": [
                    { "id": 1, "title": "M" },
                    { "id": 2, "title": "L" }
                ],
                "material": "Cotton",
                "size&fit": "Regular Fit",
                "specification": "Elegant and trendy.",
                "review": [],
                "productDetailsBanner": "url",
                "printType": [],
                "designStyle": []
            },
            {
                "id": 9,
                "title": "Hooded",
                "image": "https://stylewear.us/wp-content/uploads/2021/06/White-Round-Neck-T-Shirt-1.jpg",
                "desc": "Hooded t-shirt for a sporty style.",
                "color": [
                    { "id": 1, "title": "Grey" },
                    { "id": 2, "title": "Navy Blue" }
                ],
                "size": [
                    { "id": 1, "title": "M" },
                    { "id": 2, "title": "L" }
                ],
                "material": "Blended Fabric",
                "size&fit": "Regular Fit",
                "specification": "Soft and cozy.",
                "review": [],
                "productDetailsBanner": "url",
                "printType": [],
                "designStyle": []
            },
            {
                "id": 10,
                "title": "Tie-Dye",
                "image": "https://cdna.lystit.com/photos/2013/11/11/asos-bluemarl-sleeveless-tshirt-with-pocket-product-1-14820151-550445720.jpeg",
                "desc": "Tie-dye t-shirt for a boho-inspired look.",
                "color": [
                    { "id": 1, "title": "Multicolor" }
                ],
                "size": [
                    { "id": 1, "title": "M" },
                    { "id": 2, "title": "L" }
                ],
                "material": "Cotton",
                "size&fit": "Regular Fit",
                "specification": "Colorful and unique.",
                "review": [],
                "productDetailsBanner": "url",
                "printType": [],
                "designStyle": []
            }
        ]
    }
        ;

    return (
        <Layout>
            <div className='container'>
                <div className='py-5 p-0'><h2 className='fw-bold'>{products.title}</h2></div>
                <div className="row">
                    {products.data.map((category) => (
                        <div
                            className="col-md-3"
                            key={category.id}
                            style={{ cursor: 'pointer' }}
                            onClick={() => navigate(ROUTE_PATHS.PRODUCT_DETAILS.replace(':id', category.id.toString()))}
                        >
                            <div className="card mb-3" style={{ borderRadius: "5px", overflow: "hidden", position: "relative" }}>
                                <div className="card-body p-0" style={{ position: "relative", backgroundColor: "" }}>
                                    {/* Top-right corner element */}
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: "10px",
                                            right: "10px",
                                            height: "35px",
                                            width: "35px",
                                            backgroundColor: "var(--primary-color)",
                                            borderRadius: "50%", // Optional: to make it circular
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            color: "white", // Optional: text color inside the element
                                            fontWeight: "bold", // Optional: text styling
                                        }}
                                    >
                                        <FaHeart size={15} />
                                    </div>

                                    {category.image ? (
                                        <img
                                            src={`${category.image}`}
                                            alt={category.title}
                                            className="img-fluid"
                                            style={{
                                                width: "100%",
                                                height: "320px",
                                                borderRadius: "5px",
                                                objectFit: "cover", // Ensures the image covers the area without stretching
                                            }}
                                        />
                                    ) : (
                                        <p>No Image Available</p>
                                    )}
                                    <div className="py-3 px-3" style={{ height: "100px" }}>
                                        <h5 className="fw-bold">{category.title}</h5>
                                        <p style={{ fontSize: 13 }}>{category.desc}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    ))}
                </div>
                <br /><br />
            </div>
        </Layout>
    );
};

export default Product;
