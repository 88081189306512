import React from 'react';
import { Layout } from '../../components';


const PrivacyPolicy: React.FC = () => {
    return (
        <Layout><div className="container-fuild" style={{ backgroundColor: '#f4f9f7' }}><div className="container py-4">
            <br />
            <h1 className="text-center mb-4">Privacy Policy</h1>
            <br />
            <div className="mb-4">
                <p>
                    The Print Pyramid values and respects its customer’s privacy in keeping their information secure. We collate customer information to provide a better shopping experience and to communicate about our new products and when our new products, promotions and services are launched.

                    <br /><br />We always look forward to protect your privacy and confidentiality with respect to the information we collect during your order. We never ever sell or give your personal or financial details to any third parties without your knowledge. We will be sharing your information with certain third parties only after your acceptance.

                    <br /><br />The privacy policy that we have devised, describes your personal information we collect about you, the reasons to why we collect it, the way how we use it and in what circumstances we would share it with third parties. Our policy also describes the methods we have devised for collection and usage of your personal information. If you have any questions or queries about our privacy policy, don’t hesitate or keep the doubts to yourself, please contact us via email at: E-mail : help@theprintpyramid.com We always aim to intimate you about any future changes that occur in our information collation practices and therefore we will let you know by posting the policy changes on our Web site or by communicating directly with you.
                </p>
                <br />

            </div>
            <br />

        </div></div></Layout>
    );
};

export default PrivacyPolicy;
