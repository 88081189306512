import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Modal, Image, Button } from 'react-bootstrap';
import { Layout } from '../../../components';
import { Rating } from 'react-simple-star-rating';
import { Divider } from 'antd';
import Konva from 'konva';
import { useMediaQuery } from 'react-responsive';

// Define the type for color object
interface Color {
    id: number;
    colorCode: string;
    colorName: string;
}

// Define the type for print size objects
type PrintSize = {
    id: number;
    title: string;
};

const ProductDetails: React.FC = () => {

    // COLOR 
    const [selectedColor, setSelectedColor] = useState<Color | null>(null);
    const colors: Color[] = [
        { id: 1, colorCode: '#FF5733', colorName: 'Red' },
        { id: 2, colorCode: '#33FF57', colorName: 'Green' },
        { id: 3, colorCode: '#3357FF', colorName: 'Blue' },
        { id: 4, colorCode: '#F1C40F', colorName: 'Yellow' },
        { id: 5, colorCode: '#8E44AD', colorName: 'Purple' }
    ];

    // Size
    const [frontPrintSize, setFrontPrintSize] = useState<PrintSize | null>(null);
    const [backPrintSize, setBackPrintSize] = useState<PrintSize | null>(null);
    const printSizes: PrintSize[] = [
        { id: 1, title: 'A4' },
        { id: 2, title: 'A3' },
        { id: 3, title: 'A2' },
        { id: 4, title: 'A1' },
        { id: 5, title: 'A0' },
    ];

    // Front | Back Tab
    const [activeTab, setActiveTab] = useState<'front' | 'back'>('front');

    // QTY
    const [quantity, setQuantity] = useState(1);
    const incrementQuantity = () => setQuantity((prev) => prev + 1);
    const decrementQuantity = () => setQuantity((prev) => (prev > 1 ? prev - 1 : 1));

    // Product Data
    const [activeDetailsTab, setActiveDetailsTab] = useState<string>('Material & Care'); // State for active tab

    const tabs = [
        { id: 1, title: 'Material & Care' },
        { id: 2, title: 'Size & Fit' },
        { id: 3, title: 'Product Specifications' },
        { id: 4, title: 'Product Details' },
    ];

    const renderTabContent = () => {
        switch (activeDetailsTab) {
            case 'Material & Care':
                return (
                    <div>
                        <h4>Material</h4>
                        <p>The product is made with high-quality materials to ensure comfort and durability.</p>
                        <ul>
                            <li>100% Cotton</li>
                            <li>Soft and breathable fabric</li>
                            <li>Pre-washed to prevent shrinkage</li>
                        </ul>
                        <h4>Care Instructions</h4>
                        <ul>
                            <li>Machine wash cold with similar colors</li>
                            <li>Tumble dry low</li>
                            <li>Do not bleach</li>
                            <li>Iron on low heat if necessary</li>
                        </ul>
                    </div>
                );
            case 'Size & Fit':
                return (
                    <div>
                        <p>The product has a relaxed fit for ultimate comfort, making it perfect for casual wear.</p>
                        <ul>
                            <li>Loose fit through the body</li>
                            <li>Roomy sleeves for added comfort</li>
                            <li>Available in regular and tall sizes</li>
                        </ul>
                    </div>
                );
            case 'Product Specifications':
                return (
                    <div>
                        <ul>
                            <li>Fabric: 100% Organic Cotton</li>
                            <li>Dimensions: 28" x 22" x 2" (L x W x H)</li>
                            <li>Weight: 0.5 lbs</li>
                            <li>Color: Available in Red, Blue, and Green</li>
                            <li>Manufacturing Process: Eco-friendly and sustainable</li>
                        </ul>
                    </div>
                );
            case 'Product Details':
                return (
                    <div>
                        <ul>
                            <li>Comfortable and breathable fabric</li>
                            <li>Perfect for all-day wear</li>
                            <li>Versatile design for both casual and semi-formal occasions</li>
                            <li>Machine washable for easy care</li>
                        </ul>
                        <h4>Customer Reviews</h4>
                        <p>Rated 4.5/5 based on 250 customer reviews.</p>
                        <p>“I love this shirt! The fabric is so soft, and it fits perfectly.” – Sarah K.</p>
                        <p>“Great quality and design. Highly recommend!” – John D.</p>
                    </div>
                );
            default:
                return null;
        }
    };

    // Konva
    const konvaContainerRef = useRef<HTMLDivElement>(null);
    const [backgroundImageSrc, setBackgroundImageSrc] = useState('');
    const [stage, setStage] = useState<Konva.Stage | null>(null);
    const [transformer, setTransformer] = useState<Konva.Transformer | null>(null);

    useEffect(() => {
        const container = document.getElementById('container');
        const konvaContainer = konvaContainerRef.current;

        if (!container || !konvaContainer) return;

        const padding = 20;
        const width = konvaContainer.offsetWidth;
        const height = konvaContainer.offsetHeight;

        const newStage = new Konva.Stage({
            container: 'container',
            width: width,
            height: height,
        });

        setStage(newStage);

        const layer = new Konva.Layer();
        newStage.add(layer);

        const group = new Konva.Group({
            draggable: true,
        });
        layer.add(group);

        let konvaBackgroundImage: Konva.Image;
        const backgroundImage = new window.Image();
        backgroundImage.crossOrigin = 'anonymous'; // Allow CORS
        if (backgroundImageSrc) backgroundImage.src = backgroundImageSrc;

        konvaBackgroundImage = new Konva.Image({
            x: 0,
            y: 0,
            image: backgroundImage,
            width: newStage.width() / 1.5,
            height: newStage.height() / 1.5,
        });

        group.add(konvaBackgroundImage);
        layer.draw();

        const overlayImage = new window.Image();
        overlayImage.src = 'https://ik.imagekit.io/ryr9cg3ij/1080_1080.png';
        overlayImage.crossOrigin = 'anonymous'; // Allow CORS
        overlayImage.onload = () => {
            const konvaOverlayImage = new Konva.Image({
                x: padding,
                y: padding,
                image: overlayImage,
                width: newStage.width() - 2 * padding,
                height: newStage.height() - 2 * padding,
                draggable: false,
            });

            layer.add(konvaOverlayImage);
            konvaOverlayImage.listening(false);

            const newTransformer = new Konva.Transformer({
                nodes: [konvaBackgroundImage],
                keepRatio: true,
                anchorFill: 'blue',
                anchorStroke: 'blue',
                anchorSize: 10,
            });

            layer.add(newTransformer);
            setTransformer(newTransformer);
            layer.draw();
        };

        const handleResize = () => {
            const newWidth = konvaContainer.offsetWidth;
            const newHeight = konvaContainer.offsetHeight;
            newStage.width(newWidth);
            newStage.height(newHeight);

            if (konvaBackgroundImage) {
                konvaBackgroundImage.width(newWidth / 1.5);
                konvaBackgroundImage.height(newHeight / 1.5);
            }

            layer.getChildren().forEach((node) => {
                if (node instanceof Konva.Image && node !== konvaBackgroundImage) {
                    node.width(newWidth - 2 * padding);
                    node.height(newHeight - 2 * padding);
                    node.position({ x: padding, y: padding });
                }
            });

            layer.draw();
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [backgroundImageSrc]);

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = () => {
                setBackgroundImageSrc(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    // Design Type Model
    const [show, setShow] = useState<boolean>(false);

    // Sample images for the gallery
    const images: string[] = [
        'https://stylewear.us/wp-content/uploads/2021/06/White-Round-Neck-T-Shirt-1.jpg',
        'https://cdna.lystit.com/photos/2013/11/11/asos-bluemarl-sleeveless-tshirt-with-pocket-product-1-14820151-550445720.jpeg',
        'https://oldnavy.gap.com/webcontent/0017/820/830/cn17820830.jpg',
        'https://cdnd.lystit.com/photos/ba96-2014/11/25/asos-black-knitted-t-shirt-with-turtleneck-product-1-25758378-1-949490312-normal.jpeg',
        'https://cdn-img.prettylittlething.com/0/6/6/4/06648b7716075d32040b63ec66ba7d053106ed96_cmb5279_1.jpg',
        'https://stylewear.us/wp-content/uploads/2021/06/White-Round-Neck-T-Shirt-1.jpg',
        'https://cdna.lystit.com/photos/2013/11/11/asos-bluemarl-sleeveless-tshirt-with-pocket-product-1-14820151-550445720.jpeg',
        'https://oldnavy.gap.com/webcontent/0017/820/830/cn17820830.jpg',
        'https://cdnd.lystit.com/photos/ba96-2014/11/25/asos-black-knitted-t-shirt-with-turtleneck-product-1-25758378-1-949490312-normal.jpeg',
        'https://cdn-img.prettylittlething.com/0/6/6/4/06648b7716075d32040b63ec66ba7d053106ed96_cmb5279_1.jpg',
        'https://stylewear.us/wp-content/uploads/2021/06/White-Round-Neck-T-Shirt-1.jpg',
        'https://cdna.lystit.com/photos/2013/11/11/asos-bluemarl-sleeveless-tshirt-with-pocket-product-1-14820151-550445720.jpeg',
        'https://oldnavy.gap.com/webcontent/0017/820/830/cn17820830.jpg',
        'https://cdnd.lystit.com/photos/ba96-2014/11/25/asos-black-knitted-t-shirt-with-turtleneck-product-1-25758378-1-949490312-normal.jpeg',
        'https://cdn-img.prettylittlething.com/0/6/6/4/06648b7716075d32040b63ec66ba7d053106ed96_cmb5279_1.jpg',
        'https://stylewear.us/wp-content/uploads/2021/06/White-Round-Neck-T-Shirt-1.jpg',
        'https://cdna.lystit.com/photos/2013/11/11/asos-bluemarl-sleeveless-tshirt-with-pocket-product-1-14820151-550445720.jpeg',
        'https://oldnavy.gap.com/webcontent/0017/820/830/cn17820830.jpg',
        'https://cdnd.lystit.com/photos/ba96-2014/11/25/asos-black-knitted-t-shirt-with-turtleneck-product-1-25758378-1-949490312-normal.jpeg',
        'https://cdn-img.prettylittlething.com/0/6/6/4/06648b7716075d32040b63ec66ba7d053106ed96_cmb5279_1.jpg',
    ];

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleImageSelect = (src: string) => {
        console.log(`Selected image: ${src}`);
        handleClose();
    };

    return (
        <Layout>
            <Container fluid className={`${isTabletOrMobile ? 'px-4' : 'px-5'}`} style={{ paddingTop: '30px', paddingBottom: '30px' }}>
                <Row>
                    <Col
                        md={6}
                        sm={12}
                        style={{
                            paddingTop: '0px',
                            paddingBottom: '20px',
                            backgroundColor: 'var(--primary-color)'
                        }}
                        className='konva-container-wrapper'
                        ref={konvaContainerRef}
                    >
                        <div id="container"></div>
                    </Col>

                    <Col
                        md={6}
                        sm={12}
                        className={`${isTabletOrMobile ? 'px-1 py-3' : 'px-4'}`}
                    >
                        <p style={{ marginBottom: 8 }}>Rokan</p>
                        <h2>Oversized Corduroy Shirt</h2>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Rating readonly allowFraction initialValue={3.5} size={18} />
                            <span style={{ marginLeft: '8px', fontSize: '14px', color: '#777', paddingTop: '6px' }}>2 reviews</span>
                        </div>
                        <h5 style={{ paddingTop: 15 }}>$349.00</h5>
                        <p style={{ paddingTop: 5, letterSpacing: 1 }}>The garments labelled as committed are products that have been produced using sustainable fibres or processes, reducing their environmental impact.</p>
                        <h6>Size: <span style={{ fontWeight: 'normal' }}>M</span></h6>
                        <div style={{ display: 'flex', marginTop: '10px', gap: '10px' }}>
                            <div style={{ padding: '2px 15px', border: '1px solid #ccc', borderRadius: '2px', cursor: 'pointer' }}>S</div>
                            <div style={{ padding: '2px 15px', border: '1px solid #ccc', borderRadius: '2px', cursor: 'pointer' }}>M</div>
                            <div style={{ padding: '2px 15px', border: '1px solid #ccc', borderRadius: '2px', cursor: 'pointer' }}>XS</div>
                            <div style={{ padding: '2px 15px', border: '1px solid #ccc', borderRadius: '2px', cursor: 'pointer' }}>L</div>
                        </div>
                        <h6 style={{ paddingTop: 15, paddingBottom: 2 }}>Color: <span style={{ fontWeight: 'normal' }}>{selectedColor && selectedColor.colorName}</span></h6>
                        <div style={{ display: 'flex', marginTop: '0px', gap: '10px' }}>
                            {colors.map((color, index) => (
                                <div
                                    key={color.id}
                                    onClick={() => setSelectedColor(color)}
                                    style={{
                                        width: '22px',
                                        height: '22px',
                                        backgroundColor: color.colorCode,
                                        borderRadius: '50%',
                                        border: `1px solid ${selectedColor?.colorCode === color.colorCode ? '#333' : 'lightgray'}`, // Highlight the selected color
                                        cursor: 'pointer',
                                        transition: 'border 0.3s ease',
                                        boxShadow: selectedColor?.colorCode === color.colorCode ? '0 0 0 4px rgba(51, 51, 51, 0.1)' : 'none',
                                    }}
                                ></div>
                            ))}
                        </div>
                        <Divider style={{ marginTop: 18, marginBottom: 18 }} />
                        <h5>Location Picker</h5>
                        <p style={{ paddingTop: 5, letterSpacing: 1, fontSize: 13 }}>Upload your customized idea image to personalise your item. Custom items cannot be returned or exchanged.</p>
                        <div style={{ display: 'flex', marginTop: '10px', gap: '10px' }}>
                            <div
                                onClick={() => setActiveTab('front')}
                                style={{ padding: '5px 15px', border: '1px solid #ccc', cursor: 'pointer', backgroundColor: activeTab === 'front' ? 'var(--primary-color)' : 'transparent', color: activeTab === 'front' ? '#fff' : 'black' }}
                            >
                                Front
                            </div>
                            <div
                                onClick={() => setActiveTab('back')}
                                style={{ padding: '5px 15px', border: '1px solid #ccc', cursor: 'pointer', backgroundColor: activeTab === 'back' ? 'var(--primary-color)' : 'transparent', color: activeTab === 'back' ? '#fff' : 'black' }}
                            >
                                Back
                            </div>
                        </div>

                        {activeTab === 'front' && (
                            <div className="front" style={{ marginBottom: '20px' }}>
                                <div style={{ display: 'flex', gap: 10, marginTop: 10, marginBottom: 10 }}>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            border: '1px solid #ccc',
                                            fontSize: '14px',
                                        }}
                                    />
                                    <button
                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            border: '1px solid #ccc',
                                            fontSize: '14px',
                                            textAlign: 'center',
                                            backgroundColor: 'var(--white-color)',
                                            cursor: 'pointer',
                                        }}
                                        onClick={handleShow}
                                    >
                                        Design Type
                                    </button>
                                </div>

                                <select
                                    style={{
                                        width: '100%',
                                        padding: '10px 30px 10px 10px',
                                        border: '1px solid #ccc',
                                        fontSize: '14px',
                                    }}
                                >
                                    <option value="">Select Front Print Size</option>
                                    {/* Replace with dynamic sizes */}
                                    <option value="1">A4</option>
                                    <option value="2">A3</option>
                                </select>
                            </div>
                        )}

                        {/* Back Content */}
                        {activeTab === 'back' && (
                            <div className="back" style={{ marginBottom: '20px' }}>
                                <div style={{ display: 'flex', gap: 10, marginTop: 10, marginBottom: 10 }}>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            border: '1px solid #ccc',
                                            fontSize: '14px',
                                        }}
                                    />
                                    <button
                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            border: '1px solid #ccc',
                                            fontSize: '14px',
                                            textAlign: 'center',
                                            backgroundColor: 'var(--white-color)',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            console.log('Design Type button clicked for Back');
                                        }}
                                    >
                                        Design Type
                                    </button>
                                </div>

                                <select
                                    style={{
                                        width: '100%',
                                        padding: '10px 30px 10px 10px',
                                        border: '1px solid #ccc',
                                        fontSize: '14px',
                                    }}
                                >
                                    <option value="">Select Back Print Size</option>
                                    {/* Replace with dynamic sizes */}
                                    <option value="1">A4</option>
                                    <option value="2">A3</option>
                                </select>
                            </div>
                        )}
                        <Divider style={{ marginTop: 18, marginBottom: 18 }} />
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                                marginTop: '20px',
                            }}
                        >
                            {/* Quantity Controls */}
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    border: '1px solid #ccc',
                                    overflow: 'hidden',
                                }}
                            >
                                <div
                                    onClick={decrementQuantity}
                                    style={{
                                        padding: '8px 10px',
                                        border: 'none',
                                        cursor: 'pointer',
                                    }}
                                >
                                    -
                                </div>
                                <div
                                    style={{
                                        padding: '8px 15px',

                                        fontSize: '16px',
                                        minWidth: '40px',
                                        textAlign: 'center',
                                    }}
                                >
                                    {quantity}
                                </div>
                                <div
                                    onClick={incrementQuantity}
                                    style={{
                                        padding: '8px 10px',
                                        border: 'none',
                                        cursor: 'pointer',
                                    }}
                                >
                                    +
                                </div>
                            </div>

                            {/* Buy Now Button */}
                            <div
                                style={{
                                    padding: '10px 20px',
                                    border: '1px solid #ccc',
                                    backgroundColor: 'var(--secondary-color)',
                                    color: '#fff',
                                    fontSize: '14px',
                                    cursor: 'pointer',
                                    flex: 1,
                                    textAlign: 'center',
                                }}
                                onClick={() => console.log('Buy Now clicked')}
                            >
                                Buy Now
                            </div>

                            {/* Add to Cart Button */}
                            <div
                                style={{
                                    padding: '10px',
                                    border: '1px solid #ccc',
                                    fontSize: '25px',
                                    cursor: 'pointer',
                                    width: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                                onClick={() => console.log('Add to Cart clicked')}
                            >
                                ♡
                            </div>
                            <div
                                style={{
                                    padding: '10px',
                                    border: '1px solid #ccc',
                                    fontSize: '20px',
                                    cursor: 'pointer',
                                    width: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                                onClick={() => console.log('Add to Cart clicked')}
                            >
                                🛍️
                            </div>
                        </div>
                    </Col>
                </Row>
                <br />
                <Divider />
                <div style={{ marginTop: '20px' }}>
                    {/* Tab Headers */}
                    <div
                        style={{
                            display: 'flex',
                            gap: '10px',
                            borderBottom: '1px solid #ccc',
                            marginBottom: '15px',
                            overflowX: 'auto',  // Make the tabs horizontally scrollable
                            WebkitOverflowScrolling: 'touch',  // Smooth scrolling on iOS devices
                        }}
                    >
                        {tabs.map((tab) => (
                            <button
                                key={tab.id}
                                onClick={() => setActiveDetailsTab(tab.title)}
                                style={{
                                    padding: '10px 10px',
                                    border: 'none',
                                    borderBottom: activeDetailsTab === tab.title ? '2px solid var(--primary-color)' : 'none',
                                    background: 'transparent',
                                    cursor: 'pointer',
                                    fontWeight: activeDetailsTab === tab.title ? 'bold' : 'normal',
                                    color: activeDetailsTab === tab.title ? 'var(--primary-color)' : '#777',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {tab.title}
                            </button>
                        ))}
                    </div>

                    {/* Tab Content */}
                    <div
                        style={{
                            padding: '10px',
                            border: '1px solid #ccc',
                        }}
                    >
                        {renderTabContent()}
                    </div>
                </div>

                <Modal show={show} onHide={handleClose} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Select Design Type</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: '400px', overflowY: 'auto' }}>
                        <Container>
                            <Row className="g-1"> {/* Gap reduced with g-1 */}
                                {images.map((src, index) => (
                                    <Col
                                        key={index}
                                        xs={4} // For mobile, 3 images per row
                                        sm={2} // For larger screens, 6 images per row
                                        className="mb-1"
                                    >
                                        <Image
                                            src={src}
                                            alt={`Image ${index + 1}`}
                                            thumbnail
                                            style={{
                                                cursor: 'pointer',
                                                height: '100px', // Set image height
                                                objectFit: 'cover',
                                            }}
                                            onClick={() => handleImageSelect(src)}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>


            </Container>
        </Layout>
    );
};

export default ProductDetails;
