import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FaShippingFast, FaUndo, FaLock, FaHeadphonesAlt } from 'react-icons/fa';

const FeatureList: React.FC = () => { 
    const featureItems = [
        {
            icon: <FaShippingFast className="feature-icon" size={30} style={{ paddingTop: 10 }} />,
            title: 'Free Shipping',
            description: 'For all Orders Over $100',
        },
        {
            icon: <FaUndo className="feature-icon" size={30} style={{ paddingTop: 10 }} />,
            title: '30 Days Returns',
            description: 'For an Exchange Product',
        },
        {
            icon: <FaLock className="feature-icon" size={30} style={{ paddingTop: 10 }} />,
            title: 'Secured Payment',
            description: 'Payment Cards Accepted',
        },
        {
            icon: <FaHeadphonesAlt className="feature-icon" size={30} style={{ paddingTop: 10 }} />,
            title: 'Support 24/7',
            description: 'Contact us Anytime',
        },
    ];

    return (
        <div className='container py-3'>
            {/* Desktop view */}
            <div className="row feature-row d-none d-md-flex justify-content-center">
                {featureItems.map((item, index) => (
                    <div className="col-12 col-md-3 feature-item text-center border p-2" key={index}>
                        {item.icon}
                        <h4 className='pt-3'>{item.title}</h4>
                        <p style={{ fontSize: 13 }}>{item.description}</p>
                    </div>
                ))}
            </div>

            {/* Mobile view as a Swiper */}
            <div className="d-md-none">
                <Swiper
                    spaceBetween={10}
                    slidesPerView={1}
                    loop
                    autoplay={{ delay: 2500 }}
                    centeredSlides={true} // Center the slides
                >
                    {featureItems.map((item, index) => (
                        <SwiperSlide key={index}>
                            <div className="feature-item text-center border p-2">
                                {item.icon}
                                <h1 className='pt-2'>{item.title}</h1>
                                <p style={{ fontSize: 13 }}>{item.description}</p>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
};

export default FeatureList;
