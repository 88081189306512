import React from 'react'
import { Layout } from '../../components';

const ThankYouScreen = () => {
  return (
    <Layout>
      <div className="container-fuild" style={{ backgroundColor: '#f4f9f7' }}>
        <h1>ThankYouScreen</h1>
      </div>
    </Layout>
  )
}
  ;
export default ThankYouScreen;