import React from 'react';
import { ROUTE_PATHS } from '../../routes';
import { Link } from 'react-router-dom';
import '../../assets/css/Auth.css';

const ForgotPassword: React.FC = () => { 
  return (
    <div> 
      <div className="auth-wrapper">
    <div className="left-side">
      <div className="image-content">
      </div>
    </div>
    <div className="right-side">
      <form className="auth-form forgot-password-form">
        <div className="logo">
          {/* Replace with your logo */}
          <img src="/path-to-your-logo.png" alt="Logo" />
        </div>
        <h2>Forgot Password</h2>
        <p>Please enter your email address to receive a password reset link.</p>
        <div className="input-container">
          <input type="email" id="email" name="email" placeholder="Email" required />
        </div>
        <button type="submit" className="auth-button">Send Reset Link</button>
        <div className="links">
          <Link to={ROUTE_PATHS.LOGIN}>Back to Login</Link>
        </div>
      </form>
    </div>
  </div>  
    </div>
  );
};

export default ForgotPassword;
